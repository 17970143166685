import { useI18n } from 'vue-i18n';
import usePrice from 'Composables/usePrice.js';
import { mapActions } from 'CommonUtils/store/state';

const storageKey = 'compareProducts';
const productsToCompare = ref([]);

const readStorageProducts = () => {
  if (typeof window === 'undefined') return;
  const sessionProductsItem = sessionStorage.getItem(storageKey);
  if (!sessionProductsItem) return;

  const sessionProducts = JSON.parse(sessionProductsItem);
  return sessionProducts;
};

const writeStorageProducts = () => {
  if (typeof window === 'undefined') return;
  const sessionProducts = JSON.stringify(productsToCompare.value);
  return sessionStorage.setItem(storageKey, sessionProducts);
};

const initComparatorState = () => {
  const sessionProducts = readStorageProducts() || [];
  productsToCompare.value = sessionProducts;
};

initComparatorState();

const useProductCompare = (currentProduct) => {

  const productsLimit = 4;
  const { t } = useI18n();
  const limitExceededMessage = t('comparator.product.limitExceeded', { limit: productsLimit });

  const { addMessage } = mapActions('toastMessageState', { addMessage: 'addMessage' });

  const getCurrentColor = computed(() => {
    const { _my_colors } = currentProduct.value;
    if (!_my_colors?.length) return;

    const myColor = _my_colors.find(({ preselected }) => preselected);
    if (myColor) return myColor;

    const [firstColor] = _my_colors;
    return firstColor;
  });

  const { price } = usePrice({ product: currentProduct, color: getCurrentColor });

  const mapProductToCompare = () => {
    const { id, title, brand, _base_url, categories } = currentProduct.value;
    const { image, hasPriceRange } = getCurrentColor.value ?? {};
    return {
      id,
      _base_url,
      title,
      brand,
      image,
      hasPriceRange,
      price: price.value,
      category: categories[0]?.name,
    };
  };

  const addProduct = () => {
    const productToCompare = mapProductToCompare();
    productsToCompare.value = [...productsToCompare.value, productToCompare];
    writeStorageProducts();
  };
  const removeProduct = () => {
    productsToCompare.value = productsToCompare.value.filter(({ id }) => id !== currentProduct.value?.id);
    writeStorageProducts();
  };
  const clearProducts = () => {
    productsToCompare.value = [];
    writeStorageProducts();
  };

  const hasExceededLimit = computed(() => productsToCompare.value.length >= productsLimit);
  const isProductChecked = computed(() => productsToCompare.value.some(({ id }) => id === currentProduct.value?.id));
  const inputCheckDisabled = computed(() => !isProductChecked.value && hasExceededLimit.value);
  const isFirstProduct = computed(() => !productsToCompare.value.length);

  const toggleCompare = () => {
    if (isProductChecked.value) return removeProduct();
    if (!hasExceededLimit.value) {
      if (isFirstProduct.value) {
        const onlySameCategoryMessage = t('comparator.product.onlySameCategory', {
          category: currentProduct.value.categories[0]?.name
        });
        addMessage(onlySameCategoryMessage);
      }
      return addProduct();
    }

    return addMessage(limitExceededMessage);
  };

  const productCompareModel = computed({
    get: () => isProductChecked.value,
    set: toggleCompare,
  });

  return {
    clearProducts,
    inputCheckDisabled,
    productCompareModel,
    productsLimit,
    productsToCompare,
    removeProduct,
    toggleCompare,
  };
};

export default useProductCompare;
