import MarketplaceUtils from 'CommonUtils/marketplace';

const usePrice = ({ product, color }) => {
  
  const price = computed(() => {
    if (!product.value || !color.value) return;
    
    const {
      min_discount: discount,
      price: unit_price,
      sale_price: unit_sale_price,
      stock
    } = product.value;
    
    const defaultPrice = { discount, unit_price, unit_sale_price };
    const selectedColorFirstSku = getMinPriceSku(color.value.variants);
    if (!selectedColorFirstSku?.price) return defaultPrice;
    const mkpPreferredPrice = MarketplaceUtils.updatePriceProductPreviewFromMarketplace(stock, selectedColorFirstSku?.stockReference);
    if (mkpPreferredPrice) return mkpPreferredPrice;
    const pumOrPackComposition = selectedColorFirstSku?.pumOrPackComposition;
    const selectedSkuPrice = {
      discount: selectedColorFirstSku.discount,
      lot_price: getPriceAsNumber(selectedColorFirstSku.lot_price),
      unit_price: selectedColorFirstSku.price,
      unit_price_agrupation: selectedColorFirstSku.unit_price,
      unit_price_pum: getPriceAsNumber(selectedColorFirstSku.unit_price_pum),
      unit_sale_price: getPriceAsNumber(selectedColorFirstSku.sale_price),
      ...(pumOrPackComposition && { pumOrPackComposition }),
    };
    return selectedSkuPrice;
  });

  const getMinPriceSku = (variants) => {
    if (!variants?.length) return;

    const [firstVariant] = variants
    if (variants.length === 1) return firstVariant;

    const minPriceSku = variants.reduce((minPriceVariant, variant) => {
      const { sale_price, price } = variant;
      const { sale_price: minSalePrice, price: minPrice } = minPriceVariant;

      const current = getPriceAsNumber(sale_price || price);
      const min = getPriceAsNumber(minSalePrice || minPrice);

      if (current < min) return variant;
      return minPriceVariant;
    }, firstVariant);

    return minPriceSku;
  };

  const getPriceAsNumber = (priceString) => {
    if (typeof priceString !== 'string') {
      return priceString;
    }
    const parts = priceString.split(',');
    const integer = parts[0].replace('.', '')
    const decimal = parts[1];
    return decimal ? Number(integer + '.' + decimal) : Number(integer);
  }

  return { price };
}

export default usePrice;